import React from "react";
import styled from "styled-components";
import { Container } from "@p14/components";
import config from "@p14/config";

export const Root = styled(Container)`
  background-color: #d8d8d8;

  @media ${config.media.tabletOnly} {
    max-width: 100%;
  }

  @media ${config.media.mobile} {
    padding: 0;
  }
`;

export const Figure = styled.figure`
  height: 0;
  width: 100%;
  margin: 0 0 54px;
  overflow: hidden;
  position: relative;
  background-color: #000;
  height: 406px;

  @media ${config.media.mobile} {
    height: 300px;
    margin-bottom: 24px;
  }
`;

export const Img = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;

export const Strap = styled.img`
  top: 0;
  z-index: 20;
  max-height: 90%;
  position: absolute;

  &:first-child {
    left: 10px;
  }
  &:last-child {
    right: 10px;
  }

  @media ${config.media.mobile} {
    max-height: 70%;
  }
`;

export const Preview = () => (
  <Root>
    <Figure>
      <Strap src="/static/main-label-1.png" />
      <Img src="/static/main/slider/1.jpg" />
      <Strap src="/static/main-label-2.png" />
    </Figure>
  </Root>
);

export default Preview;

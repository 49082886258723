import React from 'react';
import * as M from './_';
import Layout from '@p14/layouts';

export const meta = {
  title: '«Пироговская, 14», клубный дом в Хамовниках от Элберт Инвестмент.',
  keywords: 'Пироговская, 14,  купить, апартаменты, квартиры, жилой, комплекс, элитная, недвижимость, Хамовники, жизнь, Москва, выбрать',
  description: 'Официальный сайт дома «Пироговская, 14». Новый адрес в Хамовниках, в нескольких минутах от исторического центра. Ваша личная Москва: с небольшим частным садом, каминами и библиотекой от Andrew Macken и Hesse Macken Studio.',
};

export const IndexScreen = props => (
  <Layout {...props} {...meta}>
    <M.Preview/>
    <M.Sections/>
    <M.Apartments/>
    <M.ContactForm/>
  </Layout>
);

export default IndexScreen;


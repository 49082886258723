import React from 'react';
import T from 'prop-types';
import axios from 'axios';
import styled, {keyframes} from 'styled-components';
import * as HOC from '@p14/HOC';
import * as Components from '@p14/components';
import {compose, withState, withHandlers, withStateHandlers, setPropTypes, onlyUpdateForPropTypes} from 'recompose';
import config from '@p14/config';
import data from '@p14/texts/main';

export const Root = styled.form`
  padding: 80px 0 100px;
  max-width: 627px;
  margin: 0 auto;
  text-align: center;

  @media ${config.media.mobile} {
    padding-left: 20px;
    padding-right: 20px;

    > label {
      text-align: left;
      overflow: hidden;

      > input {
        float: left;
      }

      > span {
        overflow: hidden;
        display: block;
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  margin: 0 0 46px;
  text-align: center;
  font-weight: bold;
  font-family: ${config.fonts.serif};
  text-transform: uppercase;
  color: ${config.colors.text};
`;

export const Row = styled.fieldset`
  margin: 0;
  border: 0;
  padding: 0;
  overflow: hidden;
  text-align: left;
`;

export const Input = styled.input`
  float: left;
  width: 308px;
  height: 46px;
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  outline: none;
  padding: 0;
  margin-bottom: 34px;
  border-bottom: 1px solid ${config.colors.main};
  font-size: 16px;
  font-family: ${config.fonts.sansSerif};
  color: ${config.colors.dark};

  &::placeholder {
    opacity: 1;
    color: ${config.colors.text};
  }

  &:first-child {
    margin-right: 11px;
  }

  @media ${config.media.mobile} {
    width: 100%;
  }
`;

export const Label = styled.label`
  display: block;

  & span {
    display: block;
    font-size: 16px;
    margin-bottom: 12px;
    color: ${config.colors.text};
    font-family: ${config.fonts.sansSerif};
  }

  & textarea {
    font-size: 16px;
    font-family: ${config.fonts.sansSerif};
    width: 100%;
    resize: none;
    outline: none;
    height: 150px;
    display: block;
    padding: 8px;
    border: 1px solid ${config.colors.main};
  }
`;

export const Textarea = ({title, ...props}) => (
  <Label>
    <span>{title}</span>
    <textarea {...props}/>
  </Label>
);

export const rotate360 = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`;

export const Button = styled.button`
  width: 199px;
  height: 56px;
  text-align: center;
  border: 1px solid ${config.colors.main};
  line-height: 56px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: ${props => props.loading ? 'transparent' : config.colors.dark};
  font-size: 14px;
  outline: none;
  background-color: ${props => props.loading ? config.colors.main : 'transparent'};
  transition: background-color 0.3s ease 0s, color 0.15s ease 0s;
  position: relative;
  will-change: background-color, color;

  &:hover:not(:disabled) {
    background-color: ${config.colors.main};
  }

  &:disabled {
    cursor: ${props => props.loading ? 'progress' : 'not-allowed'};
  }

  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: 3px solid ${config.colors.main};
    border-top: 3px solid ${config.colors.light};
    border-right: 3px solid ${config.colors.light};
    margin: -15px 0 0 -15px;
    background-color: ${config.colors.main};
    opacity: ${props => props.loading ? 1 : 0};
    transition: opacity 0.3s ease 0s;
    animation: ${rotate360} 2s linear infinite;
    will-change: opacity;
  }
`;

export const Thanks = styled.h4`
  margin: 22px auto 0;
  text-align: center;
  color: ${config.colors.main};
  font-family: 'Austin';
  font-size: 16px;
  letter-spacing: 0.1px;
`;

export const ContactForm = ({
  tr,
  name,
  email,
  message,
  isAgreed,
  isLoading,
  isFinished,
  onEmailChange,
  onNameChange,
  onMessageChange,
  onIsAgreedChange,
  onSubmit,
}) => (
  <Root onSubmit={!email.trim() || !name.trim() || !message.trim() || !isAgreed || isLoading ? null : onSubmit}>
    <Title>{tr('formTitle')}</Title>

    <Row>
      <Input
        required
        name="name"
        type="text"
        value={name}
        autoComplete="name"
        placeholder={tr('nameLabel')}
        onChange={onNameChange}
        />

      <Input
        required
        name="email"
        type="email"
        value={email}
        autoComplete="email"
        placeholder={tr('emailLabel')}
        onChange={onEmailChange}
        />
    </Row>

    <Row>
      <Textarea
        required
        name="message"
        value={message}
        title={tr('messageLabel')}
        onChange={onMessageChange}
        />
    </Row>

    <Components.Radio
      isChecked={isAgreed}
      style={{margin: '30px 0'}}
      label={tr('checkboxLabel')}
      onChange={onIsAgreedChange}
      />

    <Button loading={isLoading} disabled={!email.trim() || !name.trim() || !message.trim() || !isAgreed || isLoading} type="submit">
      {tr('buttonLabel')}
    </Button>

    {isFinished ? <Thanks>{tr('thanks')}</Thanks> : null}
  </Root>
);

export const enhance = compose(
  HOC.withTranslation(data),
  withStateHandlers(
    ({email = '', name = '', message = '', isAgreed = false}) => ({email, name, message, isAgreed}),
    {
      onEmailChange: ({email}) => e => ({email: e.target.value}),
      onNameChange: ({name}) => e => ({name: e.target.value}),
      onMessageChange: ({message}) => e => ({message: e.target.value}),
      onIsAgreedChange: ({isAgreed}) => () => ({isAgreed: !isAgreed}),
    }
  ),
  withState('isLoading', 'onIsLoadingChange', false),
  withState('isFinished', 'onIsFinishedChange', false),
  withHandlers({
    onSubmit: props => ev => {
      ev.preventDefault();
      props.onIsLoadingChange(true);
      axios.post('/mail', {
        email: props.email,
        name: props.name,
        message: props.message,
      }).then(() => {
        props.onIsFinishedChange(true);
        props.onIsLoadingChange(false);
        props.onEmailChange({target: {value: ''}});
        props.onNameChange({target: {value: ''}});
        props.onMessageChange({target: {value: ''}});
        props.onIsAgreedChange();
      }).catch(err => {
          console.error(err);
          props.onIsLoadingChange(false);
        });
    },
  }),
  onlyUpdateForPropTypes,
  setPropTypes({
    email: T.string.isRequired,
    name: T.string.isRequired,
    message: T.string.isRequired,
    isAgreed: T.bool.isRequired,
    isLoading: T.bool.isRequired,
    isFinished: T.bool.isRequired,

    onEmailChange: T.func.isRequired,
    onNameChange: T.func.isRequired,
    onMessageChange: T.func.isRequired,
    onIsAgreedChange: T.func.isRequired,
    onSubmit: T.func.isRequired,
    tr: T.func.isRequired,
  }),
);

export default enhance(ContactForm);

export default {
  ru: {
    sections: [{
      size: 'xs',
      title: 'Хамовники',
      link: '/hamovniki',
      image: '/static/section-1.jpg',
      caption: 'Парки «зеленого пояса Москвы», набережные, лучшие рестораны и архитектурные памятники – на расстоянии вытянутой руки. Самый харизматичный район принадлежит вам.',
    }, {
      size: 'm',
      title: 'Стиль жизни',
      link: '/lifestyle',
      images: [{
        image: '/static/section-2.jpg',
        caption: 'Это не гостиная, а лобби «Пироговской, 14». Мы&nbsp;стремились сделать его продолжением дома – с каминами, библиотекой и диванами, как в небольшом закрытом клубе.',
      }, {
        image: '/static/lifestyle/gym.jpg',
        caption: 'Фитнес-зал, оборудованный тренажёрами TechnoGym, к&nbsp;вашим услугам в любое время дня и ночи. Скажите&nbsp;консьержу, и он пригласит для вас персонального тренера из студии ProTrener.',
      }, {
        image: '/static/lifestyle/slider/1.jpg',
        caption: 'Все апартаменты «Пироговской, 14» полностью готовы к&nbsp;жизни, с отделкой от дизайн-бюро Hesse Macken. От&nbsp;студии&nbsp;на одного человека до пентхауса – найдите&nbsp;свою&nbsp;степень простора.',
      }]
    }, {
      size: 'xs',
      title: 'Команда',
      link: '/team',
      image: '/static/section-3.jpg',
      caption: 'Познакомьтесь с архитекторами и дизайнерами проекта. Мы&nbsp;собрали международную dream team, чтобы создать продуманный современный дом, где прекрасна каждая деталь.',
    }, {
      size: 'xl',
      title: 'Архитектура',
      link: '/architecture',
      image: '/static/main/arch.jpg',
      caption: 'Проект ручной работы, во всех смыслах.<br/>Скрытый от глаз во дворе, сдержанный<br/>и элегантный.',
    }, {
      size: 'xl',
      title: 'Дизайн',
      link: '/design',
      image: '/static/main/design.jpg',
      caption: 'Работа лондонского бюро Hesse Macken:<br/>рафинированная простота, роскошные материалы<br/>для отделки и очень хороший вкус.',
    }],
    apartmentsTitle: 'АПАРТАМЕНТЫ',
    apartmentsDescription: 'Все апартаменты «Пироговской, 14» полностью готовы к жизни – с отделкой и техникой. Мы не обставляем апартаменты мебелью. Об остальном уже позаботились: от светлой кухни, целиком оборудованной техникой Miele,<br/>до ванной комнаты в греческом мраморе.',
    apartmentsCaption: '53 апартамента на 9 этажах',
    apartmentsButton: 'Выбор апартамента',
    formTitle: 'Обратная связь',
    nameLabel: 'Ваше имя',
    emailLabel: 'E-mail',
    messageLabel: 'Расскажите, что вас интересует',
    checkboxLabel: 'Я соглашаюсь с <a target="_blank" href="/policy">правилами обработки персональных</a> данных.',
    buttonLabel: 'Отправить',
    thanks: 'Ваше сообщение отправлено.',
  },


  en: {
    sections: [{
      size: 'xs',
      title: 'Хамовники',
      link: '/hamovniki',
      image: '/static/section-1.jpg',
      caption: 'Парки «зеленого пояса Москвы», набережные, лучшие рестораны и архитектурные памятники – на расстоянии вытянутой руки. Самый харизматичный район принадлежит вам',
    }, {
      size: 'm',
      title: 'Стиль жизни',
      link: '/lifestyle',
      image: '/static/section-2.jpg',
      caption: 'Ваша личная Москва: с небольшим частным садом, камином&nbsp;и библиотекой.',
    }, {
      size: 'xs',
      title: 'Команда',
      link: '/team',
      image: '/static/section-3.jpg',
      caption: 'Познакомьтесь с архитекторами и дизайнерами проекта. Мы собрали международную dream team, чтобы создать продуманный современный дом, где прекрасна каждая деталь',
    }, {
      size: 'xl',
      title: 'Архитектура',
      link: '/architecture',
      image: '/static/main/arch.jpg',
      caption: 'Проект ручной работы, во всех смыслах.<br/>Скрытый от глаз во дворе, сдержанный<br/>и элегантный',
    }, {
      size: 'xl',
      title: 'Дизайн',
      link: '/design',
      image: '/static/main/design.jpg',
      caption: 'Работа лондонского бюро Hesse Macken:<br/>рафинированная простота, роскошные материалы<br/>для отделки и очень хороший вкус',
    }],
    apartmentsTitle: 'АПАРТАМЕНТЫ',
    apartmentsDescription: 'Все апартаменты «Пироговской, 14» полностью готовы к жизни – с отделкой и техникой. Мы не обставляем апартаменты мебелью. Об остальном уже позаботились: от светлой кухни, целиком оборудованной техникой Miele, до ванной комнаты в греческом мраморе.',
    apartmentsCaption: '53 апартамента на 9 этажах',
    apartmentsButton: 'Выбор апартамента',
    formTitle: 'Contact form',
    nameLabel: 'Ваше имя',
    emailLabel: 'E-mail',
    messageLabel: 'Расскажите, что вас интересует',
    checkboxLabel: 'Я соглашаюсь с <a target="_blank" href="/">правилами обработки персональных</a> данных.',
    buttonLabel: 'Отправить',
    thanks: 'Спасибо за ваше сообщение',
  },
};

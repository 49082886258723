import React from 'react';
import styled from 'styled-components';
import _Link from 'gatsby-link';
import * as Components from '@p14/components';
import * as HOC from '@p14/HOC';
import {compose} from 'recompose';
import data from '@p14/texts/main';
import config from '@p14/config';

export const Root = styled.section`
  text-align: center;
  padding: 60px 0 67px;
  background-color: rgba(245, 243, 241, 0.54);
`;

export const Title = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  font-family: ${config.fonts.serif};
  color: ${config.colors.text};
  margin: 0;
`;

export const SubTitle = styled.p`
  margin: 32px auto 40px;
  font-size: 16px;
  font-family: ${config.fonts.sansSerif};
  max-width: 620px;
  color: ${config.colors.text};
  text-align: left;
  line-height: 20px;
`;

export const Figure = styled.figure`
  width: 100%;
  margin: 0 0 34px;
  overflow: hidden;
  position: relative;
  text-align: center;
`;

export const Image = styled.img`
  max-width: 660px;
  vertical-align: bottom;

  @media ${config.media.mobile} {
    max-width: 100%;
  }
`;

export const Caption = styled.figcaption`
  border-top: 1px solid ${config.colors.main};
  padding-top: 20px;
  font-size: 14px;
  font-family: ${config.fonts.sansSerif};
  color: ${config.colors.text};
  text-align: center;
`;

export const Link = styled(_Link)`
  width: 199px;
  height: 56px;
  text-align: center;
  border: 1px solid #b48b72;
  line-height: 56px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #b48b72;
  font-size: 14px;
  transition: background-color 0.3s ease 0s;
  will-change: background-color;

  &:hover {
    color: #4a4a4a;
    background-color: #b48b72;
  }
`;

export const Apartments = ({tr}) => (
  <Root>
    <Components.Container>
      <Title>{tr('apartmentsTitle')}</Title>
      <SubTitle dangerouslySetInnerHTML={{__html: tr('apartmentsDescription')}}/>
      <Figure>
        <Image src="/static/apartments.png"/>
        <Caption>{tr('apartmentsCaption')}</Caption>
      </Figure>

      <Link to="/apartments">{tr('apartmentsButton')}</Link>
    </Components.Container>
  </Root>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(Apartments);
